<template>
    <div class="h-100">
        <bip-card :showForm="showForm"
                  :bip="bipProjectData"
                  :variant="selVersion"
                  :location="location"

                  :abp-base="abpBase"
                  :reg-abp-base="abpBase"
                  :spf-list="spfList"

                  :region-list="regionList"
                  :dataTypeList="dataTypeList"
                  :direction-list="directionList"
                  :gp-list="gpList"
                  :bip-cif-list="bipCifList"
                  :criteria-values="criteriaValues"
                  :bip-link-criterias="bipLinkCriterias"
                  :bip-link-types="bipLinkTypes"
                  :dict-unit="dictUnit">
        </bip-card>
    </div>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.css';
    import 'bootstrap-vue/dist/bootstrap-vue.css';
    import store from '@/services/store';
    import BipCard from '@/modules/budget/bip/bip-card.vue';
    import {
        BipProjectDataClass,
        defineBip,
        findItem,
        getRowKey, itemNameLocale,
        makeToast, nameLocale,
        sortByField
    } from "./bip-types";

    export default {
        name: 'bip-card-out',
        components: {BipCard},
        data() {
            return {
                location: null,
                regionList: [],
                spfList: [],
                dataTypeList: [],
                directionList: [],
                gpList: [],
                bipCifList: [],
                criteriaValues: new Map(),
                bipLinkCriterias: [],
                bipLinkTypes: [],
                dictUnit: [],

                selVersion: null,
                ebkMap: new Map(),
                abpBase: [],

                bipProjectData: null,
                showForm: false,

                expTypes: [
                    { value: 0, name_ru: "Переходящие проекты", name_kk: "Ауыспалы жобалар", name_en: "Transition projects" },
                    { value: 1, name_ru: "Новые инициативы", name_kk: "Жаңа бастамалар", name_en: "New initiatives" }
                ]
            };
        },

        async mounted() {
            nameLocale(this.expTypes);
            await this.getLocation();
            await this.loadBipPackageDicts();

            const urlArray = location.hash.split('?')
            if (urlArray.length>0) {
                const queryString = urlArray[1];
                const urlParams = new URLSearchParams(queryString);
                const bip_code = urlParams.get('bip_code');
                const variant = urlParams.get('variant');
                if (bip_code !== null && variant !== null) {
                    await this.loadBipPackage(bip_code, variant);
                    // await this.loadBip(bip_code, variant);
                }
            }
        },

        computed: {
            userUiid() {
                return store.state.user.sub;
            },

            localCode() {
                return store.state._instanceCode;
            }
        },

        methods: {
            async getLocation() {
                try {
                    const response = await fetch("/api-py/get-budget-obl/" + this.localCode)
                        .then((response) => response.json());
                    this.location = response;
                } catch (error) {
                    makeToast(this, "danger",
                        "Ошибка запроса /get-budget-obl", error.toString());
                } finally {
                    console.log(new Date().toISOString(), "bip-free", this.location);
                    if (this.location && this.userUiid) {
                        await this.loadRegionList();
                    }
                }
            }, // запрос локации по глобальному коду области

            async loadBip(bip_code, variant) {
                try {
                    let response = await fetch('/api-py/bip-card/' + bip_code + '/' + variant);
                    response = await response.json();
                    this.bipProjectData = response;
                    console.log('form', this.bipProjectData);
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса loadBip', error.toString());
                    return;
                }

            }, // загрузка списка БИПов

            async loadBipPackageDicts() {
                let bipCriteriaValues = [];

                const params = [
                    "budget_data_types",
                    "bip_project_type_list",
                    "bip_project_object_list",
                    "agreement_status",
                    "program",
                    "bip_cif_list",
                    "unit",
                    "bip_link_types",
                    "bip_link_criterias",
                    "bip_criteria_values"
                ];
                try {
                    const response = await fetch(
                        encodeURI("/api-py/bip-package-dicts/" + JSON.stringify(params)))
                        .then((response) => response.json());

                    this.dataTypeList = response["budget_data_types"];
                    this.directionList = response["bip_project_type_list"];
                    this.objectList = response["bip_project_object_list"];
                    this.statusList = response["agreement_status"].sort(
                        sortByField("name_ru"),
                    );
                    this.gpList = response["program"];

                    this.dictUnit = response["unit"].sort(sortByField("code"));
                    this.bipCifList = response["bip_cif_list"].sort(
                        sortByField("code"),
                    );
                    this.bipLinkTypes = response["bip_link_types"].sort(
                        sortByField("id"),
                    );
                    this.bipLinkCriterias = response["bip_link_criterias"];
                    bipCriteriaValues = response["bip_criteria_values"];
                } catch (error) {
                    makeToast(this, "danger", "Ошибка загрузки /bip-package-dicts",
                        error.toString());
                } finally {
                    console.log(new Date().toISOString(), "bip-free BipPackageDicts");
                    nameLocale(this.dataTypeList);
                    nameLocale(this.directionList);
                    for (const item of this.directionList) {
                        nameLocale(item.object_types);
                    }
                    nameLocale(this.objectList);
                    nameLocale(this.statusList);
                    nameLocale(this.gpList);
                    nameLocale(this.dictUnit);
                    nameLocale(this.bipCifList);
                    nameLocale(this.bipLinkTypes);
                    nameLocale(this.bipLinkCriterias);
                    nameLocale(bipCriteriaValues);

                    for (const row of bipCriteriaValues) {
                        this.criteriaValues.set(getRowKey(row, ["criteria", "link", "year"]), row);
                    }

                    for (const blc of this.bipLinkCriterias) {
                        try {
                            const cif = this.bipCifList.filter((row) => row.code === blc.criteria);

                            if (cif.length > 0) {
                                for (const [key, value] of Object.entries(cif[0])) {
                                    if (!["id", "begin_date", "end_date"].includes(key)) {
                                        this.$set(blc, key, value);
                                    }
                                }
                                this.$set(blc, "unit_item", findItem(blc.unit, "national_symbol", this.dictUnit));
                            }

                            const blt = this.bipLinkTypes.filter((row) => row.id === blc.link);
                            if (blt.length > 0) {
                                for (const [key, value] of Object.entries(blt[0])) {
                                    if (key !== "id") {
                                        this.$set(blc, key, value);
                                    }
                                }

                                this.$set(blc, "begin_year", new Date(blt[0].begin_date).getFullYear());
                                if (blt[0].end_date !== null) {
                                    this.$set(blc, "end_year", new Date(blt[0].end_date).getFullYear());
                                } else {
                                    this.$set(blc, "end_year", null);
                                }
                            }
                        } catch (error) {
                            console.log("err(criteria) =>", blc.id, error.toString());
                        }
                    }
                }
            },

            async loadBipPackage(bip_code, variant) {
                const params = JSON.stringify({
                    variant: variant,
                    mode_code: 'bip',
                    mode: 'free',
                    bip_code: bip_code
                });
                try {
                    const response = await fetch(`/api-py/bip-free-package/${encodeURI(params)}`)
                        .then((response) => response.json());
                    this.ebkMap = new Map(Object.entries(response.abpMap));

                    this.spfList = response.spfList.sort(sortByField('spf')).filter((row) => row.type === 4);
                    nameLocale(this.spfList, 'spf');

                    this.agrMap = await new Map(Object.entries(response.agrMap));
                    
                    this.bipProjectData = await response.bipForm;
                    this.selVersion = await response.version;

                } catch (error) {
                    makeToast(this, "danger", "Ошибка загрузки /bip-package", error.toString());
                } finally {
                    for (const ebk of this.ebkMap.values()) {
                        itemNameLocale(ebk, 'abp');
                        itemNameLocale(ebk.value, 'abp');
                        for (const prg of ebk.value.child) {
                            itemNameLocale(prg, 'prg');
                            itemNameLocale(prg.value, 'prg');
                            for (const ppr of prg.value.child) {
                                itemNameLocale(ppr, 'ppr');
                                itemNameLocale(ppr.value, 'ppr');
                            }
                        }
                        this.abpBase.push(ebk.value);
                    }
                    console.log(new Date().toISOString(), 'bip-free version = ', this.selVersion);
                    console.log(new Date().toISOString(), 'bip-free abpBase = ', this.abpBase.length);
                    console.log(new Date().toISOString(), 'bip-free = ', this.bipProjectData);
                    defineBip(this, this.bipProjectData, 'grade');
                    this.showForm = true;
                }
            },

            async loadRegionList() {
                try {
                    const response = await fetch("/api-py/get-kato-regions/" + this.location.code)
                        .then((response) => response.json());

                    for (const region of response) {
                        if (region["code"].substring(2, 3) !== "0") {
                            try {
                                const locals = await fetch("/api-py/get-kato-localities/" + region["code"].substring(0, 4))
                                    .then((response) => response.json());
                                nameLocale(locals);
                                locals.sort(sortByField("name"));
                                this.$set(region, "localityList", locals);
                                this.regionList.push(region);
                            } catch (error) {
                                makeToast(this, "danger", "Ошибка запроса /get-kato-localities/"
                                    + region["code"].substring(0, 4), error.toString());
                            }
                        }
                    }
                } catch (error) {
                    makeToast(this, "danger", "Ошибка загрузки /get-kato-regions", error.toString());
                } finally {
                    console.log(new Date().toISOString(), "budget regionList = ", this.regionList);
                    nameLocale(this.regionList);

                    // setTimeout(() => {
                    //     if (this.location && this.regionList.length === 0) {
                    //         this.loadRegionList();
                    //     }
                    // }, 2000);
                }
            }, // загрузка районов по области
        }
    };
</script>